<template>

  <div>

    <!-- 
  <the-modal class="col-lg-8 offset-lg-2" v-show="videoActive">
    <b-icon-x id="close-x" @click="closeEverything" style="position: absolute; color: white; z-index: 150; right: -50px; height: 35px; width: 35px; opacity: .5;" class="d-none d-lg-inline"></b-icon-x>
    <video
      class="embed-responsive embed-responsive-16by9 video"
      controls
      ref="indexVideo"
      src="https://unityworks-a.akamaihd.net/anon.unityworks/3/Z/R/46672175_Honda_Program_DR_Video_211105_C_211109090024_4.mp4"
      type='video/mp4'>
    </video>
  </the-modal>
-->

    <b-row class="w-100 ml-auto mr-auto index-content" style="max-width: 1200px;">
      <b-col xl="auto" id="hero-message-container">

        <div style="position: absolute; height: 120px; width: 100%; z-index: 100;" class="d-md-block">
          <transition name="fadeFirst">
            <h1 class="hero-message-description"
              style="position: absolute; width: 100%; margin-top: 40px; text-transform: uppercase">Monthly Newsletters
              from HDS</h1>
          </transition>
          <transition name="fadeSecond">
            <h1 class="hero-message-title" style="position: absolute; width: 100%; text-transform: uppercase">What's
              New?</h1>
          </transition>
        </div>

        <!--
      <a href="#" class="">
        <img
          @click="playVideo()"
          class="video-thumb"
          src="@/assets/dr_logos_play_btn.png"
        />
      </a>


        <img
        @click="playVideo()"
        id="video-thumb"
        src="@/assets/video_thumb_civic.jpg"
      /> -->
        <div id="index-text-box">
          <p class="text-white" style="font-size: 18px;">
            Click to view this month's HDS newsletter, aptly named "The Monthly Driver". Check back next month for the
            low-down on everything new and exciting in the Honda Digital Solutions program!
          </p>
        </div>
        <div style="margin-left: 10px;">
          <a :href="newsletterUrl" target="_blank">
            <button type="button" class="btn btn-outline-light mt-2 pr-3 pl-3 mr-2">
              <!--<img style="width: 24px; height: 24px; margin-right: 4px; padding-bottom: 3px; text-transform: uppercase;" src="@/assets/info.svg"> -->
              View Latest
            </button>
          </a>
          <!--
        <a href="https://login.smartdealerportal.com/login">
          <button type="button" class="btn btn-outline-light mt-2 pr-3 pl-3">
            <img style="width: 19px; height: 19px; margin-right: 5px; padding-bottom: 1px;" src="@/assets/rocket.svg">
            ENROLL NOW
          </button>
        </a>
      -->
        </div>
      </b-col>
    </b-row>

    <b-row id="cover-img"></b-row>

  </div>

</template>

<script>

/* import TheModal from '@/components/TheModal.vue' */
/* import { BIconX } from 'bootstrap-vue' */

export default {
  name: 'Home',
  components: {
  /*  TheModal, */
  /*  BIconX */
  },

  metaInfo: {
    title: 'Home'
  },

  props: {
    newsletterUrl: String,
  },

  data() {
    return {
      formErrors: {},
    };
  },

  watch: {
    $route: "fetchData",
    videoActive: function() {
      if (!this.videoActive) {
        // this.resetSupportForm();
        this.stopVideo();
      }
    }
  },

  methods: {
    
    playVideo() {
      window.scrollTo(0,0);
      this.indexVideo.play();
      this.$store.commit("toggleModal");
      this.$store.commit("toggleVideo");
    },

    stopVideo() {
      this.indexVideo.pause();
      this.indexVideo.currentTime = 0;
    },

    closeEverything() {
      this.$store.commit("closeEverything");
    },

  },

  computed: {

    videoActive() {
      return this.$store.state.videoActive ? true : false;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },

  }
}
</script>

<style scoped>

  .video-thumb {
    opacity: 0.9;
    transition: all 0.03s ease-in-out;
  }

  .video-thumb:hover {
    opacity: 1;
    filter: brightness(1.1);
  }

  .fadeFirst-enter {
    opacity: 0;
    margin-left: 50px;
  }

  .fadeFirst-enter-active {
    transition: all 0.3s ease-in-out;
  }

  .fadeFirst-leave-active {
    transition: all 0.3s ease-in-out;
  }
  
  .fadeFirst-leave-to {
    opacity: 0;
    margin-left: 80px;
  }

  .fadeSecond-enter {
    opacity: 0;
    margin-left: -50px;
  }

  .fadeSecond-enter-active {
    transition: all 0.3s ease-in-out;
  }

  .fadeSecond-leave-active {
    transition: all 0.3s ease-in-out;
  }
  
  .fadeSecond-leave-to {
    opacity: 0;
    margin-left: 80px;
    margin-top: 10px;
  }

  #close-x:hover {
    cursor: pointer;
    opacity: 1;
  }

  @media screen and (min-width: 1400px) {

    #hero-message-container {
      width: 90%;
      max-width: 1000px;
      position: absolute;
      z-index: 50;
      margin-top: 70px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    .btn-outline-light {
      background-color:rgba(0, 0, 0, .6);
      border: 1.4px solid white;
      font-weight: 400;
      border-radius: 30px;
      transition: none;
    }

    .btn-outline-light:hover {
      background-color:rgba(0, 0, 0, .8);
      color: white;
      transition: none;
    }

    .btn-outline-light:active {
      background-color:rgba(0, 0, 0, .8) !important;
      color: white !important;
      transition: none;
    }

    #cover-img {
      position: abolute;
      z-index: 5;
      background-image: url('../assets/newsletter.jpg');
      background-repeat: no-repeat;
      background-position: 30%;
      background-size: cover;
      border-bottom: 3px solid #69a1cf;
      height: 800px;
    }

    .video-thumb {
      margin-top: 150px;
    }

    #index-text-box {
      width: 430px;
      margin-left: 10px;
      margin-top: 225px;
      line-height: 1.5em;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
    }

    .hero-message-title {
      color: #175c8e;
      font-weight: 300;
     /* text-shadow: 0px 0px 4px rgba(0, 0, 0, .7); */
    }

    .hero-message-description {
      color: #175c8e;
      font-weight: 700;
      font-size: 35px;
      margin-top: -15px;
     /* text-shadow: 0px 0px 4px rgba(0, 0, 0, .7); */
    }
  }

  @media screen and (max-width: 1399px) {

    .video-thumb {
      margin-top: 150px;
    }

    #hero-message-container {
      position: absolute;
      z-index: 50;
      width: 90%;
      max-width: 1200px;
      margin-top: 30px;
    }

    .video {
      position: absolute;
      z-index: 250;
      background-color: black;
      border-radius: 5px;
    }

    .video:focus {
      outline: none;
    }

    .btn-outline-light {
      background-color:rgba(0, 0, 0, .6);
      border: 1.4px solid white;
      font-weight: 400;
      border-radius: 30px;
      transition: none;
    }

    .btn-outline-light:hover {
      background-color:rgba(0, 0, 0, .8);
      color: white;
      transition: none;
    }

    .btn-outline-light:active {
      background-color:rgba(0, 0, 0, .8) !important;
      color: white !important;
      transition: none;
    }

    #cover-img {
      position: abolute;
      z-index: 5;
      background-image: url('../assets/newsletter.jpg');
      background-repeat: no-repeat;
      background-position: -550px;
      background-size: cover;
      border-bottom: 3px solid #69a1cf;
      height: 800px;
    }

     #video-thumb:hover {
      filter: brightness(1.15);
      -webkit-box-shadow: none;
      box-shadow: none;
      cursor: pointer;
    }

    #index-text-box {
      width: 430px;
      margin-top: 250px;
      line-height: 1.5em;
      text-shadow: 0px 0px 4px rgba(0, 0, 0, .7);
    }

    .hero-message-title {
      color: #175c8e;
      font-weight: 300;
      font-size: 30px;
      line-height: 1.0;
     /* text-shadow: 0px 0px 4px rgba(0, 0, 0, .7); */
    }

    .hero-message-description {
      color: #175c8e;
      font-weight: 700;
      font-size: 20px;
      margin-top: 20px;
     /* text-shadow: 0px 0px 4px rgba(0, 0, 0, .7); */
    }
  }

  @media screen and (max-width: 768px) {

    .video-thumb {
      margin-top: 100px;
      width: 250px;
    }

    .index-content {
     /* padding-top: 60px; */
    }

    .video {
      position: fixed;
      width: 100%;
      right: 0px;
      border-radius: 0px;
      margin-top: 25%;
      z-index: 400;
    }

    #video-thumb {
      margin-top: 100px;
    }    

    #hero-message-container {
      width: 90%;
      margin-top: 95px;
    }
    
    #index-text-box {
      width: 100%;
    }    

    #cover-img {
      position: abolute;
      z-index: 5;
      background-image: url('../assets/newsletter.jpg');
      background-repeat: no-repeat;
      background-position: -900px;
      background-size: cover;
      border-bottom: 3px solid #69a1cf;
      height: 700px;
    }

    .btn {
      width: 100%;
    }

  }

</style>
